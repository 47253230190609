<template>
  <div class="serve-person">
    <vxe-table
      border
      resizable
      auto-resize
      :key="Math.random()"
      show-header-overflow
      show-overflow
      highlight-hover-row
      :data="servePersonInfoList">
      <vxe-table-column type="seq" title="序号" width="60" align="center"></vxe-table-column>
      <vxe-table-column field="name" title="姓名" minWidth="100" align="center"></vxe-table-column>
      <vxe-table-column field="idNum" title="身份证号" minWidth="100" align="center"></vxe-table-column>
      <vxe-table-column field="serviceItem" title="服务次数" minWidth="100" align="center"></vxe-table-column>
    </vxe-table>
  </div>
</template>
<script>
import {
  mapActions
} from "vuex";

export default {
  name: 'BaseInfo',
  data() {
    return {
      // 分页的参数配置
      tablePage: {
        totalResult: 0,
        currentPage: 1,
        pageSize: 10,
        align: "left",
        pageSizes: [10, 20, 50, 100, 500, 1000],
        perfect: true
      },
      servePersonInfoList: [],
    }
  },
  computed: {},
  created() {
      this.initView()
  },
  methods: {
    ...mapActions([
      "getServicePersonInfoServe"
    ]),
    initView() {
      this.getServePersonInfoList()
    }, 
    // 查询服务对象
    getServePersonInfoList() {
      this.getServicePersonInfoServe({
        current: this.tablePage.currentPage,
        size: this.tablePage.pageSize,
        param: {
          compId: this.$route.params.id
        }
      }).then(res => {
        if (res.code == 200) {
          this.servePersonInfoList = res.data.records;
          this.tablePage.totalResult = +res.data.total;
        }
      })
    },
  },
}
</script>
<style lang="scss" scoped>

</style>
