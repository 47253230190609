<template>
  <div class="core-serve">
    <vxe-button status="primary" content="添加" style="margin: 0 0 6px 0" @click="addServe"></vxe-button>
    <vxe-table
      border
      resizable
      auto-resize
      :key="Math.random()"
      show-header-overflow
      show-overflow
      highlight-hover-row
      :data="coreServeList">
      <vxe-table-column type="seq" title="序号" width="60" align="center"></vxe-table-column>
      <vxe-table-column field="serviceType" title="服务分类" minWidth="100" align="center"></vxe-table-column>
      <vxe-table-column field="serviceName" title="服务项目" minWidth="100" align="center"></vxe-table-column>
      <vxe-table-column field="serviceContent" title="服务内容介绍" minWidth="100" align="center"></vxe-table-column>
      <vxe-table-column title="操作" width="240">
        <template #default="{ row }">
          <vxe-button type="text" status="primary" content="修改" @click="editCoreServe(row)"></vxe-button>
          <vxe-button type="text" status="primary" content="删除" @click="delCoreServe(row.id)"></vxe-button>
        </template>
      </vxe-table-column>
    </vxe-table>
    <!-- 核心服务的表单弹框 -->
    <el-dialog title="核心服务" :visible.sync="showCoreServe" width="35%">
      <el-form ref="coreServeForm" :rules="coreServeRules" :model="coreServeForm" label-width="120px">
        <el-form-item label="服务分类" prop="serviceType">
          <el-select v-model="coreServeForm.serviceType" placeholder="请选择" style="width:100%;">
              <el-option
                v-for="item in serviceTypeList"
                :key="item.dictPidVal"
                :label="item.dictDesc"
                :value="item.dictPidVal">
              </el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="服务项目" prop="serviceName">
          <el-input v-model="coreServeForm.serviceName" autocomplete="off" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="服务内容介绍">
          <el-input type="textarea" :rows="2" v-model="coreServeForm.serviceContent" placeholder="请输入"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="showCoreServe = false">取 消</el-button>
        <el-button type="primary" @click="coreServeSave('coreServeForm')">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {
  mapActions
} from "vuex";

export default {
  name: 'BaseInfo',
  data() {
    return {
      // 分页的参数配置
      tablePage: {
        totalResult: 0,
        currentPage: 1,
        pageSize: 10,
        align: "left",
        pageSizes: [10, 20, 50, 100, 500, 1000],
        perfect: true
      },
      showCoreServe: false,
      coreServeList: [],
      coreServeForm: {
        serviceType: '',
        serviceName: '',
        serviceContent: ''
      },
      serviceTypeList: [],
      coreServeRules: {
        serviceType: [
          { required: true, message: '请输入服务分类', trigger: 'blur' }
        ],
        serviceName: [
          { required: true, message: '请输入服务项目', trigger: 'blur' }
        ],
      },
    }
  },
  computed: {},
  created() {
    this.getSysDictList('genService', 'serviceTypeList') //服务分类
    this.initView()
  },
  methods: {
    ...mapActions([
      "getHomeCoreSeriveListServe",
      "addHomeCoreSeriveServe",
      "delHomeCoreSeriveServe",
      "getSysDictServe"
    ]),
    initView() {
      this.getCoreServeList()
    }, 
    addServe() {
      this.showCoreServe = true
    },
    editCoreServe(itemData) {
      this.showCoreServe = true
      this.coreServeForm = {
        serviceType: itemData.serviceType,
        serviceName: itemData.serviceName,
        serviceContent: itemData.serviceContent,
        id: itemData.id
      }
    },
    delCoreServe(id) {
      this.$XModal.confirm("您确定要删除该数据?").then(type => {
        if (type === "confirm") {
          console.log('执行删除操作');
          this.delete(id)
        }
			})
    },
    delete(id) {
      this.delHomeCoreSeriveServe({
        id: id
      }).then(res => {
        if (res.code == 200) {
          this.$XModal.message({
            message: '删除成功',
            status: "success"
          });
          this.getCoreServeList()
        }
        this.loading = false;
      })
		},
    // 保存核心服务
    coreServeSave(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let params = this.coreServeForm
          params.compId = this.$route.params.id
          this.addHomeCoreSeriveServe(params).then(res => {
            if(res.code == 200) {
              this.$XModal.message({
                message: '添加成功',
                status: "success"
              });
              this.getCoreServeList()
              this.showCoreServe = false
            }
          })
        }
      });
    },
    // 查询核心服务列表
    getCoreServeList() {
      this.getHomeCoreSeriveListServe({
        current: this.tablePage.currentPage,
        size: this.tablePage.pageSize,
        param: {}
      }).then(res => {
        if (res.code == 200) {
          this.coreServeList = res.data.records;
          this.tablePage.totalResult = +res.data.total;
        }
      })
		},
    // 获取数据字典
    getSysDictList(code, codeList) {
      let params = {
        typeCode: code
      }
      this.getSysDictServe(params).then(res => {
        if(res.code == 200) {
          this[codeList] = res.data
        }
      })
    },
  },
}
</script>
<style lang="scss" scoped>

</style>
