<template>
  <div class="serve-records">
    <vxe-table
      border
      resizable
      auto-resize
      :key="Math.random()"
      show-header-overflow
      show-overflow
      highlight-hover-row
      :data="serveRecordsList">
      <vxe-table-column type="seq" title="序号" width="60" align="center"></vxe-table-column>
      <vxe-table-column field="name" title="服务对象" minWidth="100" align="center"></vxe-table-column>
      <vxe-table-column field="compName" title="服务公司" minWidth="100" align="center"></vxe-table-column>
      <vxe-table-column field="serviceItem" title="服务项目" minWidth="100" align="center"></vxe-table-column>
      <vxe-table-column field="satisfaction" title="满意度" minWidth="100" align="center"></vxe-table-column>
      <vxe-table-column field="serviceContent" title="联系方式" minWidth="100" align="center"></vxe-table-column>
    </vxe-table>
  </div>
</template>
<script>
import {
  mapActions
} from "vuex";

export default {
  name: 'BaseInfo',
  data() {
    return {
      // 分页的参数配置
      tablePage: {
        totalResult: 0,
        currentPage: 1,
        pageSize: 10,
        align: "left",
        pageSizes: [10, 20, 50, 100, 500, 1000],
        perfect: true
      },
      serveRecordsList: [],
    }
  },
  computed: {},
  created() {
      this.initView()
  },
  methods: {
    ...mapActions([
      "getServeMonthPageListServe"
    ]),
    initView() {
      this.getServeRecordsList()
    }, 
    // 查询服务记录
    getServeRecordsList() {
      this.getServeMonthPageListServe({
        current: this.tablePage.currentPage,
        size: this.tablePage.pageSize,
        param: {
          serviceCompId: this.$route.params.id
        }
      }).then(res => {
        if (res.code == 200) {
          this.serveRecordsList = res.data.records;
          this.tablePage.totalResult = +res.data.total;
        }
      })
    },
  },
}
</script>
<style lang="scss" scoped>

</style>
